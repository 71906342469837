import React, { useState } from 'react'

import { useForm, Resolver, Controller, FieldErrors } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import styles from "./Login.module.css"
import image1 from './img/image1.png'
import logo from './img/logo.png'
import { useNavigate } from 'react-router-dom';

type FormValues = {
  name: string;
  password: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  const errors: FieldErrors<FormValues> = {};

  if (!values.name) {
    errors.name = {
      type: 'required',
      message: 'Name is required.'
      
    };
  } else if (values.name.length < 3) {
    errors.name = {
      type: 'minLength',
      message: 'Name should be at least 3 characters.',
    };
  } else if (values.name.length > 30) {
    errors.name = {
      type: 'maxLength',
      message: 'Name should not exceed 30 characters.',
    };
  }

  if (!values.password) {
    errors.password = {
      type: 'required',
      message: 'Password is required.'
    };
  } else if (values.password.length < 8) {
    errors.password = {
      type: 'minLength',
      message: 'Password should be at least 8 characters.',
    };
  } else if (values.password.length > 30) {
    errors.password = {
      type: 'maxLength',
      message: 'Password should not exceed 30 characters.',
    };
  }

  return {
    values,
    errors,
  };
};



export default function Login() {
  const { register, control, handleSubmit, formState: { errors } } = useForm<FormValues>({ resolver });
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");;
  const submettingDetails = (data:any) => {
    //console.log(data)
    window.sessionStorage.setItem('token', data.name);
    if (data.name === 'admin' && data.password == 'JKFenner@123#') {
      navigate("/main")
    }
    else {
      setErrorMsg("Please enter valid credentials");
    }
    //window.location.reload();
  }
  const onSubmit = handleSubmit((data) => submettingDetails(data));

  return (
    <div className={styles.main}>
      <div className={styles.box} >
        <div className={styles.innerbox}>
          <div className={styles.forms_wrap}>
            <form onSubmit={onSubmit} autoComplete="off" className={styles.signinform}>
              <div className={styles.logo}>
                <img src={logo} alt="logo" />

              </div>

              <div className={styles.heading}>
                <h2>Welcome Back</h2>
                {/* <h6>Not registred yet?</h6>
              <a href="#" className={styles.toggle}>Sign up</a> */}
              </div>
              {errorMsg && <div className={styles.error}>{errorMsg}</div>}
              <div className={styles.actualform}>
                <div className={styles.inputwrap}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        id="standard-password-input"
                        label="Name"
                        type="text"
                        autoComplete="current-password"
                        variant="standard"
                        {...register("name")}
                        
                        fullWidth
                      />
                    )} />
                  {errors?.name && <p style={{ color: 'red', marginTop: '6px' }}>{errors.name.message}</p>}
                </div>

                <div className={styles.inputwrap}>
                  <Controller
                    name="password"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        id="standard-password-input"
                        label="Password"
                        type="password"
                        autoComplete="current-password"
                        variant="standard"
                        {...register("password")}
                        fullWidth

                        className={styles.inputwrap}
                      />
                    )}

                  />
                  {errors?.password && <p style={{ color: 'red', marginTop: '6px' }}>{errors.password.message}</p>}
                </div>

                <input type="submit" value="Sign In" className={styles.sign_btn} />
                {/* 
              <p className={styles.text}>
                Forgot your password or you login datails?
                <a href="#">Get help</a> signing in
              </p> */}
              </div>
            </form>
          </div>

          <div className={styles.carousel}>
            <div className={styles.imageswrapper}>
              <img src={image1} className={styles.image} alt="image" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
